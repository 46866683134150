import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

const { List } = require('immutable');

const getState = state => state.offers;
getState.selectorName = 'getOffersState';

const getRetailerSelected = createSelector([getState], state => state.get('retailerSelected'));

const getOfferEntities = createSelector([getState], state => state.getIn(['entities', 'offer']));

const getHistory = createSelector([getState], state => state.get('history'));

const getOffersList = createSelector([getState], state => state.get('offers'));

const getOffers = createSelector(
  [getOffersList, getRetailerSelected, getOfferEntities, getHistory],
  (offersList, retailerSelected, offersEntities, history) => {
    let offers = offersList
      .toSet()
      .subtract(history.toSet())
      .toList();

    if (retailerSelected > -1) {
      offers = offers.filter(offerId =>
        offersEntities.get(offerId).retailers.includes(retailerSelected),
      );
    }

    let offer;
    let rank;
    const ordered = [];
    offers.forEach(id => {
      offer = offersEntities.get(id);
      rank = offer.rank ? offer.rank : 1000;
      ordered.push({
        id,
        rank,
      });
    });

    ordered.sort((a, b) => {
      if (a.rank > b.rank) {
        return 1;
      }
      if (a.rank < b.rank) {
        return -1;
      }
      return 0;
    });

    return List(
      ordered.map(item => {
        return item.id;
      }),
    );
  },
);

const getOfferById = createSelector(getOfferEntities, entities =>
  memoize(({ id }) => entities.get(id.toString())),
);

const getIsFetchingOfferById = createSelector(getOfferEntities, entities =>
  memoize(({ id }) => {
    const offer = entities.get(id);
    return offer ? offer.isFetching : true;
  }),
);

const getWallet = createSelector([getOffers, getOfferEntities], (offers, offersEntities) =>
  offers.filter(offerId => offersEntities.get(offerId).inWallet),
);

const getFetching = createSelector([getState], offers => offers.get('isFetching'));
getFetching.selectorName = 'getOffersFetching';

const getError = createSelector([getState], offers => offers.get('isError'));

const getSavings = createSelector([getState], offers => offers.get('totalSavings'));

const getFutureSavings = createSelector([getWallet, getOfferById], (wallet, fnGetOfferById) =>
  wallet.reduce((acc, offerId) => acc + fnGetOfferById({ id: offerId }).discount, 0),
);

const getRedeemed = createSelector([getState], offers => offers.get('totalCouponsRedeemed'));

const getLoyaltyPlans = createSelector([getState], state =>
  state.getIn(['entities', 'loyaltyPlan']),
);

const getPrintUrl = createSelector([getState], state => state.getIn(['print', 'url']));

const getIsFetchingPrinturl = createSelector([getState], state =>
  state.getIn(['print', 'isFetching']),
);

const getIsErrorPrintUrl = createSelector([getState], state => state.getIn(['print', 'isError']));

const getNumberOfOffersInWallet = createSelector([getWallet], wallet => wallet.size);

export default {
  getRetailerSelected,
  getOfferEntities,
  getOffers,
  getOffersList,
  getWallet,
  getHistory,
  getFetching,
  getError,
  getSavings,
  getRedeemed,
  getLoyaltyPlans,
  getOfferById,
  getIsFetchingOfferById,
  getPrintUrl,
  getIsFetchingPrinturl,
  getIsErrorPrintUrl,
  getNumberOfOffersInWallet,
  getFutureSavings,
};
