import { createSelector } from 'reselect';

const getState = state => state.account;
getState.selectorName = 'getAccountState';

const getFetching = createSelector(getState, account => account.get('isFetching'));
getFetching.selectorName = 'getAccountFetching';

const isAuthenticated = createSelector(
  getState,
  account =>
    account.get('uuid') !== '' && account.get('uuid') !== null && account.get('uuid') !== undefined,
);
isAuthenticated.selectorName = 'isAuthenticated';

export default {
  getFetching,
  isAuthenticated,
};
