import { fromJS } from 'immutable';
import * as reduxHelper from '../../utils/reduxHelper';
import { Retailer, Store } from '../../../models';
import types from './types';

const initialState = fromJS({
  entities: {
    retailer: {},
    store: {},
  },
  isFetching: false,
  didInvalidate: false,
  isError: false,
  retailers: [],
  stores: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RETAILERS_REQUESTED:
      return state.merge(reduxHelper.applyRequestInfo());

    case types.RETAILERS_SUCCEEDED: {
      const { retailerList, retailerIds } = action.payload;

      const retailerEntities = {};

      retailerIds.forEach(retailerId => {
        const offer = new Retailer(retailerList[retailerId]);
        retailerEntities[retailerId] = offer;
      });

      return state.mergeDeep(
        reduxHelper.applyReceiveInfo({
          retailers: retailerIds,
          entities: {
            retailer: retailerEntities,
          },
        }),
      );
    }

    case types.STORES_SUCCEEDED: {
      const { storeList, storeIds } = action.payload;

      const storeEntities = {};

      storeIds.forEach(storeId => {
        const offer = new Store(storeList[storeId]);
        storeEntities[storeId] = offer;
      });

      return state.mergeDeep(
        reduxHelper.applyReceiveInfo({
          stores: storeIds,
          entities: {
            store: storeEntities,
          },
        }),
      );
    }

    default:
      return state;
  }
}
