/* eslint-disable no-console */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Routes from '../Routes';
import { actions as accountActions } from '../../store/modules/account';
import InstallPWA from '../../components/InstallPWA';

class App extends React.Component {
  static propTypes = {
    restoreAccount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { restoreAccount } = this.props;
    restoreAccount();
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Todos los supermercados</title>
        </Helmet>
        <Routes />
        <InstallPWA />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  restoreAccount: accountActions.restoreAccount,
};

const connected = connect(null, mapDispatchToProps);

export default connected(App);
