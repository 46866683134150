import React from 'react';
import PropTypes from 'prop-types';
import localforage from 'localforage';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import config from '../../config';
import withI18n from '../../hocs/withI18n';

import PopupContainer from './PopupContainer';
import PopupText from './PopupText';
import PopupArrow from './PopupArrow';
import PopupClose from './PopupClose';

class InstallPWA extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    isFirstTime: false,
  };

  componentDidMount() {
    this.getInstallPWAShowed();
  }

  getInstallPWAShowed = async () => {
    const isShowed = await localforage.getItem(config.INSTALL_PWA_SHOWED);
    this.setState({
      isFirstTime: isShowed === null ? true : !isShowed,
    });
  };

  closePopup = () => {
    localforage.setItem(config.INSTALL_PWA_SHOWED, true);
    this.setState({
      isFirstTime: false,
    });
  };

  render() {
    const { t } = this.props;
    const { isFirstTime } = this.state;

    const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const isWeb = !window.matchMedia('(display-mode: standalone)').matches;

    if (isIOS && isWeb && isFirstTime) {
      return (
        <PopupContainer>
          <PopupClose>
            <IconButton aria-label="Close" onClick={this.closePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </PopupClose>
          <PopupText>
            <PopupText.Title>{t('INSTALL_PWA_IOS_TITLE')}</PopupText.Title>
            <PopupText.Description>
              {t('INSTALL_PWA_IOS_TEXT')}
              <PopupText.ShareIcon />
            </PopupText.Description>
          </PopupText>
          <PopupArrow />
        </PopupContainer>
      );
    }
    return null;
  }
}

export default withI18n(InstallPWA);
