import { fromJS } from 'immutable';
import * as reduxHelper from '../../utils/reduxHelper';
import types from './types';

const initialState = fromJS({
  isFetching: false,
  didInvalidate: false,
  isError: false,
  uuid: '',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_ACCOUNT:
      return state.merge(fromJS({ ...reduxHelper.applyRequestInfo() }));
    case types.FAILED_ACCOUNT:
      return state.merge(fromJS({ ...reduxHelper.applyErrorInfo() }));
    case types.SUCCESS_ACCOUNT: {
      const { uuid } = action.payload;
      return state.merge(fromJS({ ...reduxHelper.applyReceiveInfo({ uuid }) }));
    }
    case types.CLEAR_ACCOUNT: {
      return state.set('uuid', '');
    }
    default:
      return state;
  }
}
