import { createSelectorWithDependencies as createSelector } from 'reselect-tools';
import memoize from 'lodash.memoize';
import { selectors as offersSelectors } from '../offers';

const getState = state => state.retailers;
getState.selectorName = 'getRetailersState';

const getRetailers = createSelector([getState], state =>
  state
    .get('retailers')
    .map(retailerId => state.getIn(['entities', 'retailer', retailerId.toString()])),
);

const getStores = createSelector(
  [getState, offersSelectors.getRetailerSelected],
  (state, retailerSelected) => {
    const stores = state
      .get('stores')
      .map(storeId => state.getIn(['entities', 'store', storeId.toString()]));
    if (retailerSelected > -1) {
      return stores.filter(store => store.retailer === retailerSelected);
    }
    return stores;
  },
);

const getStoreById = createSelector([getState], state =>
  memoize(id => state.getIn(['entities', 'store', String(id)])),
);

const getRetailersEntities = createSelector([getState], state =>
  state.getIn(['entities', 'retailer']),
);

const getFetching = createSelector([getState], state => state.get('isFetching'));
getFetching.selectorName = 'getRetailersFetching';

export default {
  getRetailers,
  getStores,
  getStoreById,
  getRetailersEntities,
  getFetching,
};
