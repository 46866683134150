import { createGlobalStyle } from 'styled-components';

import MontserratThin from '../static/fonts/Montserrat/Montserrat-Thin.ttf';
import MontserratThinItalic from '../static/fonts/Montserrat/Montserrat-ThinItalic.ttf';
import MontserratExtraLight from '../static/fonts/Montserrat/Montserrat-ExtraLight.ttf';
import MontserratExtraLightItalic from '../static/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf';
import MontserratLight from '../static/fonts/Montserrat/Montserrat-Light.ttf';
import MontserratLightItalic from '../static/fonts/Montserrat/Montserrat-LightItalic.ttf';
import MontserratRegular from '../static/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratItalic from '../static/fonts/Montserrat/Montserrat-Italic.ttf';
import MontserratMedium from '../static/fonts/Montserrat/Montserrat-Medium.ttf';
import MontserratMediumItalic from '../static/fonts/Montserrat/Montserrat-MediumItalic.ttf';
import MontserratSemiBold from '../static/fonts/Montserrat/Montserrat-SemiBold.ttf';
import MontserratSemiBoldItalic from '../static/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf';
import MontserratBold from '../static/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratBoldItalic from '../static/fonts/Montserrat/Montserrat-BoldItalic.ttf';
import MontserratExtraBold from '../static/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import MontserratExtraBoldItalic from '../static/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf';
import MontserratBlack from '../static/fonts/Montserrat/Montserrat-Black.ttf';
import MontserratBlackItalic from '../static/fonts/Montserrat/Montserrat-BlackItalic.ttf';

const Typographies = createGlobalStyle`
/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratThin}) format('truetype');
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratThinItalic}) format('truetype');
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratExtraLight}) format('truetype');
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratExtraLightItalic}) format('truetype');
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratLight}) format('truetype');
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratLightItalic}) format('truetype');
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratRegular}) format('truetype');
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratItalic}) format('truetype');
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratMedium}) format('truetype');
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratMediumItalic}) format('truetype');
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratSemiBold}) format('truetype');
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratSemiBoldItalic}) format('truetype');
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratBold}) format('truetype');
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratBoldItalic}) format('truetype');
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratExtraBold}) format('truetype');
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratExtraBoldItalic}) format('truetype');
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratBlack}) format('truetype');
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: local('Monstserrat'), url(${MontserratBlackItalic}) format('truetype');
}
`;

export default Typographies;
