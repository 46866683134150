import { Record } from 'immutable';

export const OFFER_KEY_ID = 'remoteOfferId';

const OfferRecord = Record({
  [OFFER_KEY_ID]: null,
  displayValue: '',
  purchaseDescription: '',
  imageUrl: '',
  consumerInstructions: '',
  displayExpiry: null,
  endOn: null,
  inWallet: false,
  soldOut: false,
  uccs: [],
  retailers: [],
  loyaltyPlan: null,
  isProcessingAction: false,
  isFetching: false,
  value: 0,
  rank: 0,
});

const formatDate = date => {
  const tmpDate = new Date(date);
  return `${tmpDate.getDate()}/${tmpDate.getMonth() + 1}/${tmpDate
    .getFullYear()
    .toString()
    .substring(2, 4)}`;
};

class Offer extends OfferRecord {
  get id() {
    return this.get(OFFER_KEY_ID);
  }

  get title() {
    return this.get('displayValue');
  }

  get description() {
    return this.get('purchaseDescription');
  }

  get image() {
    return this.get('imageUrl');
  }

  get instructions() {
    return this.get('consumerInstructions');
  }

  get expiry() {
    const date = this.get('displayExpiry') || this.get('endOn');
    return date ? formatDate(date) : '-';
  }

  get ucc() {
    return this.uccs[0];
  }

  get loyaltyPlanId() {
    const loyaltyPlan = this.get('loyaltyPlan');
    return loyaltyPlan ? loyaltyPlan.toString() : loyaltyPlan;
  }

  get hasPromo() {
    return this.loyaltyPlan !== null;
  }

  get discount() {
    return this.value / 100;
  }

  setInWallet(exists) {
    this.set('inWallet', exists);
  }
}

export default Offer;
