import localforage from 'localforage';
import pkg from '../package.json';

localforage.config({
  name: 'vales-y-cupones',
  storeName: 'ecoupon',
});

const generals = {
  version: pkg.version,
  GOOGLE_MAPS_KEY: 'AIzaSyCDyjD_MF7o1dug71D11aJ2J23vNg2WyRI',
  MAPBOX_KEY:
    'pk.eyJ1IjoianplcnBhIiwiYSI6ImNqbmc5cW12ejAwdTEzdm95ZDN2eDFjanUifQ.6vhMo8uUbPT4BQBoQnjpDw',
  walletMode: process.env.REACT_APP_WALLET_MODE,
  initialRoute: '/map',
  ACCOUNT_UID: 'Valassis-Wallet-Id',
  INSTALL_PWA_SHOWED: 'INSTALL_PWA_SHOWED',
  generalInstructions: '',
};

const twagner = {
  host: 'http://localhost:3002',
  baseURL: 'http://localhost:3002',
};

const dev = {
  host: 'https://apiwallet.ecupon.es',
  baseURL: 'https://apiwallet.ecupon.es/v1',
};

const test = {
  host: 'https://apiwalletprod.ecupon.es',
  baseURL: 'https://apiwalletprod.ecupon.es/v1',
};

const prod = {
  host: 'https://apiwalletprod.ecupon.es',
  baseURL: 'https://apiwalletprod.ecupon.es/v1',
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'test':
    config = test;
    break;
  case 'twagner':
    config = twagner;
    break;
  default:
    config = dev;
}

const retailers = [
  {
    id: 'lupa',
    name: 'Lupa',
    imageUrl: `${process.env.PUBLIC_URL}/retailers/logo-lupa.png`,
    instructions: '',
    prefix: '',
  },
  {
    id: 'marvimundo',
    name: 'Marvimundo',
    imageUrl: `${process.env.PUBLIC_URL}/retailers/logo-marvimundo.png`,
    instructions: '',
    prefix: '',
  },
];

export default {
  ...generals,
  ...config,
  retailers,
};
