import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import animationData from '../../static/animations/loader.json';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
};
/**
 * Basic Loading component
 */
const Loading = () => (
  <LoadingWrapper>
    <Lottie options={defaultOptions} height={500} width={500} isClickToPauseDisabled />
  </LoadingWrapper>
);

export default Loading;
