import React from 'react';
import { I18nContext } from '../contexts/I18nProvider';

function withI18n(Component) {
  return function I18nComponent(props) {
    return <I18nContext.Consumer>{({ t }) => <Component t={t} {...props} />}</I18nContext.Consumer>;
  };
}

export const useI18n = () => {
  const context = React.useContext(I18nContext);
  return context.t;
};

export default withI18n;
