import React from 'react';
import { Router, Route } from 'react-router';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Loading from '../../components/Loading';
import history from '../../store/history';

const InitData = Loadable({
  loader: () => import('../../containers/InitData'),
  delay: 400,
  loading: Loading,
});

// Views

const Routes = () => (
  <Router history={history} basename="/">
    <Switch>
      <Route path="/" component={InitData} />
    </Switch>
  </Router>
);

export default Routes;
