import axios from 'axios';
import localforage from 'localforage';
import config from '../config';

const { baseURL } = config;
const instance = axios.create({ baseURL });

const setAuth = async () => {
  instance.defaults.headers[config.ACCOUNT_UID] = await localforage.getItem(config.ACCOUNT_UID);
};

export { setAuth };
export default instance;
