import { call, put, all, takeLatest } from 'redux-saga/effects';
import serviceRetailers from '../../../services/retailers';
import { types as accountTypes } from '../account';
import actions from './actions';
import types from './types';

export function* getRetailers() {
  yield put(actions.requestRetailers());
  try {
    const { retailerList, retailerIds } = yield call(serviceRetailers.getList);
    const { storeList, storeIds } = yield call(serviceRetailers.getStores);

    yield put(
      actions.fetchRetailers({
        retailerList,
        retailerIds,
      }),
    );

    yield put(
      actions.fetchStores({
        storeList,
        storeIds,
      }),
    );
  } catch (e) {
    yield put(actions.failedRetailers({ message: 'Ha habido un error' }));
  }
}

function* retailerSaga() {
  yield all([takeLatest(types.GET_RETAILERS, getRetailers)]);
  yield all([takeLatest(accountTypes.SUCCESS_ACCOUNT, getRetailers)]);
}

export default retailerSaga;
